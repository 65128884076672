<template>
  <div class="wrapper">
    <!-- 用户统计   营收一览 -->
    <el-row :gutter="10">
      <el-col :span="12">
        <el-card class="count">
          <div class="title">用户统计</div>
          <div class="content">
            <div class="item" @click="$router.push({ name: 'MemberList' })">
              <div class="left">
                <span>{{ userStatic.allUserTotal }}</span>
                <span>累计用户</span>
              </div>
              <div class="right">今日+{{ userStatic.todayUserTotal }}</div>
            </div>
            <div class="item" >
              <div class="left">
                <span>{{ downloadStatic.allDownloadTotal }}</span>
                <span>总下载数</span>
              </div>
              <div class="right">今日+{{ downloadStatic.todayDownloadTotal }}</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="profit">
          <div class="title">文章统计</div>
          
          <div class="content">
            <div class="left" @click="$router.push({ name: 'articleList' })">
              <span class="yellow">{{ articleStatic.allArticleTotal }}</span>
              <span>总文章数</span>
            </div>
            <div class="right" @click="$router.push({ name: 'articleList' })">
              <span>{{ articleStatic.todayArticleTotal }}</span>
              <span>今日发布文章数</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 常用功能 -->
<!--    <el-card class="common">-->
<!--      <div class="title">常用功能</div>-->
<!--      <div class="content">-->
<!--        <el-row :gutter="10">-->
<!--          <el-col :span="8">-->
<!--            <div class="item item-left">-->
<!--              <div class="item-content">-->
<!--                <div class="left">-->
<!--                  <img src="@/assets/baoming.png" alt="" />-->
<!--                </div>-->
<!--                <div class="right">-->
<!--                  <span class="pink">报名</span>-->
<!--                  <span>-->
<!--                    <span>今日报名<i>12</i></span>-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <div class="item item-center">-->
<!--              <div class="item-content">-->
<!--                <div class="left">-->
<!--                  <img src="@/assets/zhuanke .png" alt="" />-->
<!--                </div>-->
<!--                <div class="right">-->
<!--                  <span class="blue">升期转课</span>-->
<!--                  <span>-->
<!--                    <span>今日升期<i>12</i></span>-->
<!--                    <span>今日转课<i>12</i></span>-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <div class="item item-right">-->
<!--              <div class="item-content">-->
<!--                <div class="left">-->
<!--                  <img src="@/assets/flag.png" alt="" />-->
<!--                </div>-->
<!--                <div class="right">-->
<!--                  <span class="green">记上课</span>-->
<!--                  <span>-->
<!--                    <span>今日升期<i>0</i></span>-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </div>-->
<!--    </el-card>-->
    <!-- 近期活动 -->
    <el-card class="activity">
      <div class="title">近期下载</div>
      <div class="content">
        <el-table :data="downloadList" style="width: 100%">
          <el-table-column type="index" label="序号" width="width" align="center"> </el-table-column>

          <el-table-column prop="title" label="下载标题" width="width" align="center">
            <template slot-scope="{ row }">
              <div class="small-size">{{ row['title'] ?? '-' }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="cover_image" label="封面图" width="width" align="center">
            <template slot-scope="{ row }">
              <el-image style="width: 60px; height: 60px;margin-top:5px;" :src="row['cover_image']" fit="fit"></el-image>
            </template>
          </el-table-column>

          <el-table-column prop="file_name" label="下载文件名称" width="width" align="center">
            <template slot-scope="{ row }">
              <div class="small-size">{{ row['file_name'] ?? '-' }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="nickname" label="下载用户昵称" width="width" align="center">
            <template slot-scope="{ row }">
              <div class="small-size">{{ row['user'] != null ? row['user']['nickname'] : '-'}}</div>
            </template>
          </el-table-column>

          <el-table-column prop="day" label="下载日期" width="width" align="center">
            <template slot-scope="{ row }">
              <div class="small-size">{{ row['day'] ?? '-' }}</div>
            </template>
          </el-table-column>


        </el-table>
        <el-pagination
          style="text-align: center; margin-top: 10px"
          background
          @current-change="handleCurrentChangeActivity"
          :current-page="currentPage"
          :page-size="5"
          layout=" prev, pager, next, jumper,->,total"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 今日订单 -->
<!--    <el-card class="order">-->
<!--      <div class="title">今日订单</div>-->
<!--      <div class="content">123</div>-->
<!--    </el-card>-->
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { downloadLog, indexStatic } from './api'

export default {
  name: 'Home',

  data() {
    return {
      // 待办事项
      backlogStatic: {},
      // 用户统计
      userStatic: {},
      downloadStatic: {},
      articleStatic: {},
      // 近期下载列表
      downloadList: [],
      total: 0,
      currentPage: 1
    }
  },
  created() {
    if (this.$route.query.token) {
      this.$store.commit('SET_TOKEN', this.$route.query.token)
    }
  },

  mounted() {
    this.indexStatic()
    this.getDownloadLog()
  },

  methods: {
    async indexStatic() {
      const res = await indexStatic({})

      this.userStatic = res.userStatic
      this.downloadStatic = res.downloadStatic
      this.articleStatic = res.articleStatic
      console.log(res)

    },
    // 近期下载列表
    async getDownloadLog() {
      let params = { page: this.currentPage, page_size: 5 }
      const res  = await downloadLog(params)
      this.downloadList = res.data || []
      this.total = res.total || 0
    },
    goActivityList(type) {
      switch (type) {
        case 10:
          this.$router.push({ name: 'Coupon' })
          break
        case 20:
          this.$router.push({ name: 'Popularity' })
          break
        case 30:
          this.$router.push({ name: 'Group' })
          break

        default:
          break
      }
    },
    goActivityEdit(row) {
      const { activity_type, uuid } = row
      switch (activity_type) {
        case 10:
          this.$router.push({ name: 'CouponEdit', params: { id: uuid } })
          break
        case 20:
          this.$router.push({ name: 'PopularityEdit', params: { id: uuid } })
          break
        case 30:
          this.$router.push({ name: 'GroupEdit', params: { id: uuid } })
          break

        default:
          break
      }
    },
    goActivityDetail(row) {
      const { activity_type, uuid } = row
      switch (activity_type) {
        case 10:
          this.$router.push({ name: 'CouponDetail', params: { id: uuid } })
          break
        case 20:
          this.$router.push({ name: 'PopularityDetail', params: { id: uuid } })
          break
        case 30:
          this.$router.push({ name: 'GroupDetailList', params: { id: uuid } })
          break

        default:
          break
      }
    },
    handleCurrentChangeActivity(page) {
      this.currentPage = page
      this.getActivity()
    }
    //
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  height: 120vh;
  background-color: #f0f2f5;

  .link {
    span {
      text-decoration: underline;
    }
  }
  .small-size {
    font-size: 12px;
    margin: 0;
  }
  .el-card {
    box-shadow: none;
  }
  .title {
    font-weight: 700;
    font-size: 15px;
  }
  .el-card {
    padding: 0 30px;
  }
  .item-center {
    justify-content: center;
  }
  .item-left {
    padding-left: 5em;
  }
  .item-right {
    justify-content: end;
    padding-right: 5em;
  }
  .content-padding {
    padding: 0 5em;
  }
  .pink {
    color: #e24e7e;
  }
  .blue {
    color: #0571f2;
  }
  .yellow {
    color: #f29b28;
  }
  .green {
    color: #00d1c2;
  }
  .attention {
    height: 207px;
    margin-bottom: 10px;
    .el-col:hover {
      cursor: pointer;
      background-color: #f3f8fe;
      border-radius: 10px;
    }
    .content {
      margin-top: 20px;
    }

    .item {
      display: flex;
      align-items: center;
      margin: 25px 0;
      .item-content {
        display: flex;
        align-items: center;
        cursor: pointer;
        .left {
          display: flex;
          justify-content: center;
          align-items: center;
          // width: 55px;
          // height: 55px;
          // border-radius: 50%;
          // box-shadow: 0 0 5px 2px #f0f2f5;
          // i {
          //   font-size: 25px;

          //   font-weight: 700;
          // }
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin-left: 15px;
          span {
            &:first-child {
              font-weight: 700;
              font-size: 25px;
              line-height: 30px;
            }
            &:last-child {
              color: #585858;
            }
          }
        }
      }
    }
  }

  .common {
    height: 207px;
    margin: 10px 0;

    .content {
      margin-top: 20px;
      .el-col {
        padding: 30px 0;
      }
      .el-col:hover {
        cursor: pointer;
        background-color: #f3f8fe;
        border-radius: 10px;
      }
    }
    .item {
      display: flex;
      align-items: center;

      .item-content {
        cursor: pointer;
        display: flex;
        align-items: center;
        .left {
          i {
            font-size: 40px;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 15px;
          & > span {
            &:nth-child(2) {
              display: flex;
              span {
                margin-right: 10px;
                i {
                  margin-left: 5px;
                  font-style: normal;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }

  .count {
    height: 207px;
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .item {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 0;
        &:hover {
          background-color: #f3f8fe;
          border-radius: 10px;
        }
        flex: 1;
        .left {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            &:first-child {
              font-size: 18px;
              font-weight: 700;
            }
          }
        }
        .right {
          background-color: #0571f2;
          line-height: 20px;
          padding: 0 10px;
          border-radius: 20px;
          color: #fff;
        }
      }
    }
  }
  .profit {
    height: 207px;
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 28px;
      .left,
      .right {
        flex: 1;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        &:hover {
          background-color: #f3f8fe;
          border-radius: 10px;
        }
        span {
          &:first-child {
            font-weight: 700;
            font-size: 18px;
          }
        }
      }
    }
  }
  .activity {
    min-height: 260px;
    margin-bottom: 10px;
  }
  .order {
    height: 260px;
  }
}
</style>
