import { http } from '@/http/axios.js'

export function indexStatic(params) {
    return http({
      url: `/company/index/static`,
      method: 'get',
      params: params
    })
  }



export function downloadLog(params) {
    return http({
      url: `/company/download/log/list`,
      method: 'get',
      params: params
    })
  }